<template>
  <div>
    <van-overlay :show="show" @click="show = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="top">
            <div class="top-title">Automatic loan</div>
            <div class="tips">When you liquidate the order we will</div>
            <div class="tips">automatically apply for the next loan for</div>
            <div class="tips">you to this product, and you can receive</div>
            <div class="tips">the loan more quickly</div>
            <div class="amountbox" >
              <div class="loan-title">Estimated amount for</div>
              <div class="loan-title">automatic loan</div>
            <div class="loannum">₹{{ settledAutoApplyAmount?dataMoney(settledAutoApplyAmount):"0" }}</div>
             <div class="account-list">
              Receiving account
             </div>
            <div class="account-box">
              <div class="account-left">
                Bank name
              </div>
              <div class="account-right">
                {{ data? data.bankName:'' }}
              </div>
            </div>
            <div class="account-box">
              <div class="account-left">
                IFSC
              </div>
              <div class="account-right">
                {{ data? data.bankCode:'' }}
              </div>
            </div>
            <div class="account-box" style="margin-bottom: 0;">
              <div class="account-left">
                Bank Account No
              </div>
              <div class="account-right">
                {{ data? data.bankCard:'' }}
              </div>
            </div>
            </div>

            <div class="bottom-tip" style="margin-top: 0.5333rem;">
              The pass rate of automatic loan application is
            </div>
            <div class="bottom-tips">up to <span style="font-size: 0.3467rem;color:#7578FF;">95%</span></div>

          <!-- <div style="display: flex;margin-top: 0.4533rem;" @click="clickChecked">
            <van-checkbox v-model="checked">
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                </template>
            </van-checkbox>
            <div style="font-weight: 400;color: #8F99A3;font-size: 0.3467rem;margin-left:0.1067rem">Auto reapply loan without waiting</div>
          </div> -->
            <!-- <van-button type="primary" class="bottom-btn" v-if="showBtn" @click="automaticLoan">Repayment and open the <br>automatic loan</van-button> -->
            <van-button type="primary" class="bottom-btn1" v-if="showBtn" @click="RepaymentOnly">Repay now</van-button>
            <div style="display: flex;margin-top: 0.4533rem;" @click="clickChecked">
            <van-checkbox v-model="checked">
                <template #icon="props">
                  <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
                </template>
            </van-checkbox>
            <div style="font-weight: 400;color: #8F99A3;font-size: 0.3467rem;margin-left:0.1067rem" v-if="checked">Automatic loan enabled</div>
            <div style="font-weight: 400;color: #8F99A3;font-size: 0.3467rem;margin-left:0.1067rem" v-if="!checked">Auto reapply loan without waiting</div>
          </div>
          </div>
          <div style="width: 1rem;height: 1rem;display: flex;align-items: center;justify-content: center; top: 0.1rem;right:0.2rem;position: absolute;">
            <img  src="@/assets/offbtn.png" alt="" @click="entiendo" class="close-icon" />
          </div>

        </div>

      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { dataMoney } from '@/utils/money'
import { getBankCard, getSettledAutoApply } from '@/views/normalExtension/lib.js'
export default {
  data () {
    return {
      show: false,
      showBtn: false,
      checked: true,
      data: null,
      url: null,
      loadingBtn: null,
      orderNo: null,
      settledAutoApplyAmount: null,
      activeIcon: require('@/assets/on.png'),
      inactiveIcon: require('@/assets/reoff.png')
    }
  },
  methods: {
    dataMoney,
    entiendo () {
      this.show = false
      this.checked = true
    },
    async getBankCard () {
      this.data = null
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      const res = await getBankCard()
      console.log(res, '银行卡信息')
      if (res.shake === '200') {
        this.data = JSON.parse(res.arm[0].arm)
        if (this.data) {
          loadingInstance.clear()
        }
        console.log(this.data, 'data')
      } else {
        loadingInstance.clear()
      }
    },
    clickChecked () {
      this.checked = !this.checked
      this.$managementEvents({ pageName: 'invention', action: 'required', extInfo: { type: this.checked } })
    },
    async automaticLoan () {
      if (this.loadingBtn) return
      this.loadingBtn = true

      const res = await getSettledAutoApply({
        type: true,
        // orderNo:'2024020415501093585951' //170状态 放款成功
        orderNo: this.orderNo // 180状态 放款成功
      })
      if (res.code === '200') {
        this.$router.push({ name: 'easyPay', params: { url: this.url, name: 'normalExtension' } })
      // window.location.href=this.url
      }
      setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定
    },
    // clickChecked(){
    //   this.checked=!this.checked
    // },
    async RepaymentOnly () {
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      if (this.loadingBtn) return
      this.loadingBtn = true
      const res = await getSettledAutoApply({
        type: this.checked,
        // orderNo:'2024020415501093585951' //170状态 放款成功
        orderNo: this.orderNo // 180状态 放款成功
      })
      if (res.shake === '200') {
        loadingInstance.clear()
      }
      // console.log(this.url);
      this.$router.push({ name: 'easyPay', params: { url: this.url, name: 'normalExtension' } })
      setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定
    }

  },
  mounted () {
    this.$managementEvents({ pageName: 'invention', action: 'required', extInfo: { type: true } })
    // this.getBankCard()
  }

}
</script>

<style lang="less" scoped>
.van-overlay{
  z-index: 999;
}
.img-icon{
  height: 0.4267rem;
  width: 0.4267rem;
}
.wrapper {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .block {
    width: 8.8rem;
    height: auto;
    position: relative;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    padding:0  0 0.6667rem 0;
    border-radius: 0.64rem;

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;

      .top-title {
        width: 100%;
        padding-top:  0.8rem;
        border-radius: 0.64rem;

        background: linear-gradient( 180deg, #DEDEFF 0%, rgba(255,255,255,0) 100%);
        color: #010101;
        font-size: 0.5333rem;
        font-weight: 700;
        margin-bottom: 0.26rem;
      }
      .tips{
        font-size: 0.2933rem;
        color: #626C89;
        margin-bottom: 0.1333rem;
      }
      .amountbox{
        margin-top: 0.2667rem;
        width: 7.52rem;
        height: auto;
        background: #F5F5FC;
        border-radius: 0.4267rem;
        padding: 0.7467rem 0.4rem;
      }
      .loan-title{
        // margin-top: 0.1867rem;
        color: #626C89;
        font-size: 0.3733rem;
      }
      .loannum{
        margin-bottom: 0.2667rem;
        margin-top: 0.1333rem;
        font-size: 0.7467rem;
        color: #7578FF;
        font-weight: 600;

      }
      .account-list{
        width: 100%;
        // font-weight: 600;
        margin-bottom: 0.3467rem;
        text-align: initial;
        font-size: 0.4267rem;
        color: #333333;
      }
      .account-box{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom:0.4267rem;
        .account-left{
          font-weight: 500;
          font-size: 0.32rem;
          color: #626C89;
        }
        .account-right{
          font-size: 0.32rem;
          font-weight: 600;
          color: #333333;
        }
      }
      .bottom-tip{
        margin-left: 1.5rem;
        width: 100%;
        font-size:0.3467rem;
        color: #626C89;
        text-align: left;
      }
      .bottom-tips{
        margin-left: 1.5rem;
        width: 100%;
        font-size:0.3467rem;
        color: #626C89;
        text-align: left;
      }
      .bottom-btn{
        margin-top: 0.4533rem;
         width: 6.24rem;
         height: 1.2267rem;
         font-size: 0.3733rem;
         color: #000000;
         font-weight: 800;
         background: #FFFF00;
         box-shadow: 0 0 0.16rem 0 rgba(14,13,16,0.4);
         border-radius: 0.64rem;
         border: none;
      }
      .bottom-btn1{
        margin-top: 0.4533rem;
        width: 7.52rem;
        height: 1.2267rem;
        font-size: 0.3733rem;
         font-weight: bold;
         font-weight: 600;
         background: #7578FF;
         border-radius: 0.64rem;
         color: #FFFFFF;
         border: none;
      }

    }

  }

  .close-icon {
    width: 0.64rem;
    height: 0.64rem;
    // margin-top: 1.4133rem;
    border: none;

  }
}</style>
