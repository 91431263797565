import Vue from 'vue'
import VueRouter from 'vue-router'
import order from '../views/order/index.vue'
import normalExtension from '../views/normalExtension/index.vue'
import agreeMent from '../views/agreeMent/index.vue'
import repaymentExtension from '../views/repaymentExtension/index.vue'
import easyPay from '../views/easyPay/index.vue'

Vue.use(VueRouter)
console.log('进来了')
const routes = [
  {
    path: '/order',
    name: 'order',
    component: order
  },
  {
    path: '/normalExtension',
    name: 'normalExtension',
    component: normalExtension
  },
  {
    path: '/agreeMent',
    name: 'AgreeMent',
    component: agreeMent
  },
  {
    path: '/repaymentExtension',
    name: 'repaymentExtension',
    component: repaymentExtension
  },
  {
    path: '/easyPay',
    name: 'easyPay',
    component: easyPay
  }

]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
