const dsBridge = require('dsbridge')
// const STRINGRETURN = ['8', '7', 'getRegisterPhone', 'getRegisterCode']
export default function (key, cb) {
  if (dsBridge.hasNativeMethod('ios.handle')) {
    dsBridge.call('ios.handle', JSON.stringify([key]),
      function (res) {
        console.log(res, 'res')
        if (res === 'img') {
          cb(res)
        } else {
          const confinacion = JSON.parse(res) || '""'
          cb(confinacion)
        }
      })
  }
}
