<template>
  <div class="content">
    <div class="headersbg">
    <div class="headers">
       <img src="@/assets/left.png" alt="" style="width:0.3467rem;height: 0.5867rem;" @click="go">

       <!-- <div class="box-payName">Repay</div> -->
       <div  style="width:0.3467rem;height: 0.5867rem;"></div>
        <problemFeedback :title="title"></problemFeedback>
    </div>
    </div>
    <div class="content-box">
      <div class="box-top">
       <div class="box-title">
         <div class="title-left">
          <van-skeleton title avatar  :loading="loading">
          <img :src="img" alt="" style="width: 1.0667rem;height: 1.0667rem;margin-right: 0.32rem;">
        </van-skeleton>
         <div style="color: #616162;font-size:0.3733rem;font-weight: 600;">{{ productName }}</div>
        </div>
         <div class="title-right">
          <div style="display: flex;justify-content: center;align-items: center;width: 0.5333rem;height: 0.5333rem;"  @click="showBox">
            <img :src="require('@/assets/bottom.png')" alt="" style="height: 0.5333rem;width: 0.5333rem;margin-left: 0.1867rem;" v-if="showingImage">
            <img :src="require('@/assets/bottom.png')" alt="" style="height:0.5333rem;width: 0.5333rem;margin-left: 0.1867rem;" v-if="!showingImage">
          </div>
         </div>
       </div>
       <div class="box-center">
        <div class="center-left">Extension charge</div>
         <div class="center-right">₹{{amount?dataMoney(amount):'0' }}</div>
       </div>
       <div class="box-center">
        <div class="center-left">New due date</div>
         <div class="center-right">{{delayExpiryTime?Time(delayExpiryTime):'0' }}</div>
       </div>
       <!-- <div class="show-line"  v-show="showingImage"></div> -->
       <div class="box-show" v-show="showingImage">

         <div class="show-title">
          <div class="title-left">
            Extension details:
          </div>
          <!-- <img src="@/assets/rightOne.png" alt="" style="width: 0.16rem;height: 0.2667rem;margin-right:0.7467rem;" > -->
         </div>
         <div class="box-num" >
          <div class="numBox" v-for="(item,index) in list" :key="index">
            <div class="num-left">{{item.suburb}}</div>
            <div class="num-right" v-html="item.acceptance"></div>
          </div>
        </div>
       </div>
       <div class="box-btn" @click="pay">
        Pay
       </div>
    </div>
    <div class="box-tip">
     <div class="tip-content">
        <div class="tips">1.After the UPI has expired, please re-enter the </div>
        <div class="tip-wrap">repayment page to start the repayment and get a </div>
        <div class="tip-wrap">new UPI.</div>
        <div class="tips">2.If the status of your paid order has not </div>
        <div class="tip-wrap">modified, please provide the following proof of</div>
        <div class="tip-wrap"> payment and we will handle the payment for you.</div>
     </div>
    </div>
    <div class="box-bottom">
      <div class="title" style="margin-bottom: 0.2667rem;">Upload proof of payment</div>
    <div class="mian">
      <div class="userName">
        <van-form>
        <van-field
            v-model="userfrom.utr"
            class="field-phone"
            type="digit"
            placeholder="Enter UTR"
            autocomplete="off"
            @input="clickInput"
            />
          </van-form>
      </div>
       <van-form>
          <van-field
                v-model="userfrom.amount"
                class="field-vcode"
                autocomplete="off"
                left-icon=""
                type="digit"
                placeholder="Enter the exact amount of payment"
                @click="addCurrencySymbol"
                @input="inputCurrencySymbol"
                @blur="blurCurrencySymbol"
              >
           </van-field>
          </van-form>

    </div>
    <div class="title" style="margin-top: 0.7rem;margin-bottom: 0.2667rem;">Screenshot proof (optional)</div>
    <div class="bottomImg">
      <div class="Img" v-for="(item,index) in listImg" :key="index">
        <img :src="item" alt="" style=" width: 2.2733rem;height: 2.2733rem;">
        <img src="@/assets/off.png" alt="" class="offImg" @click="delImg(index)">
      </div>
      <div class="addImg" @click="addImg" v-if="listImg.length<3">
        <img src="@/assets/addImg.png" alt="" style="height: 0.84rem;width: 0.84rem;margin-bottom: 0.2rem;">
        <div style="font-size:0.3733rem;color: #AAB0BF;">Add pictures </div>
      </div>

      <!-- <div class="addImg">
        <img src="@/assets/addImg.png" alt="" style="height: 0.5333rem;width: 0.5333rem;margin-bottom:0.2667rem;">
        <img src="@/assets/addImg.png" alt="" >
      </div>
      <div class="addImg">
        <img src="@/assets/addImg.png" alt="" style="height: 0.5333rem;width: 0.5333rem;margin-bottom:0.2667rem;">
        <div style="font-size:0.3733rem;color: #AAB0BF;">Add pictures </div>
      </div> -->
    </div>
    <van-button type="primary" class="bottom-btn"  :disabled="!userfrom.utr||!userfrom.amount" @click="Submit">Submit</van-button>
    </div>

    <agreeMent style="margin-bottom: 0.6667rem;"></agreeMent>
    </div>

    <loanDlog ref="loanDlog"></loanDlog>

  </div>
</template>

<script>
import { Toast } from 'vant'
import { dataMoney } from '@/utils/money'
import { Time } from '@/utils/time'
import agreeMent from '@/components/agreeMent.vue'
import loanDlog from '@/components/loanDlog.vue'
import { base64ToImage } from '@/utils/baseImg'
import { getOrderDetails, getDelayFlexble, getRepaymentDetail } from '@/views/repaymentExtension/lib.js'
import { getUpOrderUtr } from '@/http/repayment'
import problemFeedback from '@/components/problemFeedback.vue'
export default {
  name: 'orDer',
  components: { agreeMent, loanDlog, problemFeedback },
  data () {
    return {
      isListVisible: false,
      showingImage: false,
      title: 'problemFeedback',
      productName: null,
      delayExpiryTime: null,
      loadingBtn: false,
      loading: true,
      amount: null,
      orderNo: localStorage.getItem('orderNo') || '',
      img: null,
      userfrom: {
        utr: '',
        amount: ''
      },
      listImg: [
      ],
      list: []
    }
  },
  watch: {
    'userfrom.amount': {
      handler (ne, old) {
        if (ne.length >= old.length && ne !== '₹') {
          const amount = this.userfrom.amount.replace(/₹/g, '')
          this.$managementEvents({ pageName: 'likely', action: 'infrastructure', extInfo: { amount: amount } })
        }
      }
    }
  },
  methods: {
    dataMoney,
    Time,
    go () {
      this.$javaGet('returnH5')
    },
    showBox () {
      this.isListVisible = !this.isListVisible
      this.showingImage = !this.showingImage
    },
    // 订单详情
    async getOrderDetails () {
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      const res = await getOrderDetails({
        pageType: 2,
        orderNo: this.orderNo
      })
      console.log(res, '逾期订单详情')
      if (res.shake === '200') {
        loadingInstance.clear()
        this.loading = false
        this.productName = res.arm.notion
        this.img = base64ToImage(`${'data:image/png;base64,' + res.arm.newspaper}`)
      }
    },
    // 展期订单
    async getDelayFlexble () {
      const res = await getDelayFlexble({
        delayTerm: '',
        orderNo: this.orderNo,
        termUnit: ''
      })
      console.log(res)
      this.amount = res.arm.correctly
      this.delayExpiryTime = res.arm.engineer
      this.list = res.arm.heaven
    },
    async pay () {
      this.$managementEvents({ pageName: 'likely', action: 'mechanism', extInfo: '' })
      if (this.loadingBtn) return
      this.loadingBtn = true
      const res = await getRepaymentDetail({
        delayTerm: 2,
        orderNo: this.orderNo,
        requestType: 2
      })
      console.log(res, '支付跳转地址')
      this.$router.push({ name: 'easyPay', params: { url: res.arm.flight[0]?.crisis, name: 'repaymentExtension' } })
      setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定
    },

    addCurrencySymbol () {
      // 当输入框被点击时，如果当前值为空，则添加金额符号
      if (this.userfrom.amount === '') {
        this.userfrom.amount = '₹'
      }
    },
    clickInput () {
      this.$managementEvents({ pageName: 'likely', action: 'rent', extInfo: { utr: this.userfrom.utr } })
    },
    inputCurrencySymbol (value) {
      // 当输入框被点击时，如果当前值为空，则添加金额符号
      if (value === '') {
        this.userfrom.amount = '₹'
      }
      if (value && value.charAt(0) !== '₹') {
        this.userfrom.amount = '₹' + value
      }
    },
    blurCurrencySymbol () {
      // 当输入框被点击时，如果当前值为空，则添加金额符号
      if (this.userfrom.amount === '₹') {
        this.userfrom.amount = ''
      }
    },
    // handleScroll () {
    //   window.scroll(0, 0)
    // },
    delImg (index) {
      console.log(index)
      this.listImg.splice(index, 1)
    },
    addImg () {
      // 调取ios方法获取图片
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      this.$managementEvents({ pageName: 'likely', action: 'regional', extInfo: '' })
      this.$javaGet('backImg', (res) => {
        console.log(res, '回来的图片base64')
        if (res === 'img') {
          return loadingInstance.clear()
        }
        if (this.listImg.length === 2 && res[0].length > 1) {
          Toast('Only select up to 3 images')
        } else if (this.listImg.length === 1 && res[0].length > 2) {
          Toast('Only select up to 3 images')
        } else if (this.listImg.length === 0 && res[0].length > 3) {
          Toast('Only select up to 3 images')
        } else {
          res[0].forEach(item => {
            this.listImg.push(`data:image/png;base64,${item}`)
          })
        }
        loadingInstance.clear()
        this.$managementEvents({ pageName: 'likely', action: 'progressive', extInfo: { img: this.listImg } })
      }).finally(() => {
        console.log('进来')
      })
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },

    async Submit () {
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      this.$managementEvents({ pageName: 'likely', action: 'go', extInfo: '' })
      const amount = this.userfrom.amount.replace(/₹/g, '')
      const res = await getUpOrderUtr({
        orderNo: this.orderNo,
        utr: this.userfrom.utr,
        img: this.listImg.map(item => this.dataURLtoFile(item)),
        amount: amount
      })
      if (res.shake === '200') {
        Toast('Submit Success')
        this.$managementEvents({ pageName: 'likely', action: 'province', extInfo: { utr: this.userfrom.utr } })
        setTimeout(() => {
          loadingInstance.clear()
          this.$javaGet('backHome')
        }, 500)
      } else {
        setTimeout(() => {
          loadingInstance.clear()
          // this.$javaGet('backHome')
        }, 1000)
      }
      console.log(res)
      console.log('点击了')
    },
    showLoan () {
      this.$refs.loanDlog.show = true
    }

  },
  // beforeDestroy () {
  //   window.removeEventListener('scroll', this.handleScroll, true)
  // },
  mounted () {
    // window.addEventListener('scroll', this.handleScroll, true)
    this.getOrderDetails()
    this.getDelayFlexble()
    this.$managementEvents({ pageName: 'likely', action: 'absolutely', extInfo: { loanOrderId: this.orderNo } })
  }
}
</script>

<style lang="less" scoped>
::v-deep .van-field__control::-webkit-input-placeholder {
  color: #8F99A3;
  font-size:0.3467rem;
  font-weight: 600;
}

.content{
  overflow:hidden;
  background: #FFFFFF;
  padding:0 0 1.12rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .headersbg{
    width: 100%;
    height: 5.6533rem;
    background-image: url('@/assets/header.png');
    background-size: 100% 100%;
    .headers{
    position: fixed;
    background: #7578ff;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99;
      width: 100%;
    height: 2.26rem;
    padding: 0.84rem 0.64rem 0 0.64rem;
    display:flex;
    justify-content: space-between;
    align-items: center;

  }

  }
  .content-box{
    width: 100%;
    overflow:auto;
  height: calc(100vh - 2.56rem);
  margin-top:-3.26rem;
  padding: 0 0.3733rem;
  .box-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0.64rem 0;
    border-radius: 0.64rem;
    background: #EDEEFA;
    .box-title{
      padding: 0 0.2933rem;
      width: 100%;
      margin-bottom: 0.64rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-left{
        display: flex;
        align-items: center;

      }
      .title-right{
        display: flex;
        align-items: center;
      }
    }
    .box-center{
      padding: 0 0.2933rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.4267rem;
      .center-left{
        font-size: 0.32rem;
        color: #75778B;
        font-weight: 500;
      }
      .center-right{
        font-weight: 600;
       font-size: 0.32rem;
       color: #636364;
      }
    }
    .show-line{
        width: 9.2533rem;
        height: 0.0267rem;
        background: #FFFFFF;
        opacity: 0.16;
       margin: 0.5067rem 0;
       }
    .box-show{
      padding: 0 0.2933rem;
      width: 100%;
      margin-top: 0.48rem;
       display: flex;
       flex-direction: column;

       .show-title{
        display: flex;
        justify-content: space-between;
        .title-left{
           font-size: 0.4267rem;
           color: #616162;
           font-weight: 600;
        }
       }
       .box-num{
        display: flex;
        margin-top: 0.3467rem;
        flex-direction: column;
        .numBox{
          display: flex;
          justify-content: space-between;

          margin-bottom: 0.4267rem;
          .num-left{
            font-size:0.32rem;
            color: #75778B;
          }
          .num-right{
            font-weight: 600;
            font-size:0.32rem;
            color: #616162;
          }
        }
      }
    }
    .box-btn{

      margin-top:0.5267rem;
      width: 5.3333rem;
      height: 1.2267rem;
      background: #7578FF;
      border-radius: 0.6133rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.4267rem;
      font-weight:600;
      color: #FFFFFF;
    }

  }
  .box-tip{
    padding: 0.2667rem 0.4267rem;
    margin-top: 0.3733rem;
    width: 100%;
    height: 3.84rem;
    display: flex;
    align-items: center;
  //  justify-content: center;
  background: #FBE7E7;
  // box-shadow: 0 0.1067rem 0.2667rem 0 rgba(14,16,36,0.81);
    border-radius:0.64rem;
    .tip-content{
      display: flex;
      flex-direction: column;
      text-align: initial;
      .tips{
        font-size: 0.3567rem;
        color: #D80B33;
        margin-bottom: 0.1333rem;
      }
      .tip-wrap{
        margin-bottom: 0.1333rem;
        font-size: 0.3567rem;
        color: #D80B33;
      }
    }
  }
  .box-bottom{
    margin-top: 0.3733rem;
    background: #FFFFFF;
    padding: 0.48rem 0.56rem 0.48rem 0.56rem;
    border-radius: 0.2667rem;
    margin-bottom: 1.0667rem;
    .title{
    width: 100%;
    text-align: initial;
    color: #626C89;
    // font-weight: 600;
    font-size: 0.3733rem;
  }
  ::v-deep  .van-field__control{
  font-size: 0.4267rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: center;
  color: #010101 !important;
}

  .mian{
      .userName{
        display: flex;
        margin-bottom: 0.2667rem;
             .field-phone{
              width: 8.16rem;
              height: 1.2267rem;
              margin-bottom: 0.1733rem;
              border-radius: 0.5867rem;
              // border: 0.0267rem solid #0B718D;
              display: flex;
              align-items: center;
              background: #F5F5FC;
             }
      }
      .field-vcode{
        width: 8.16rem;
        height: 1.2267rem;
          border-radius: 0.5867rem;
          display: flex;
          align-items: center;
          // border: 0.0267rem solid #0B718D;
          background: #F5F5FC;
      }

  }
  .bottomImg{
    width: 100%;
    display: flex;
    flex: 1;
    margin-bottom:0.9867rem;
    // justify-content:space-between;
    .addImg{
      // margin-right: 0.5333rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 2.2733rem;
      height: 2.2733rem;
      background: #F5F5FC;
      border-radius: 0.2133rem;
      // border: 0.0267rem solid #484C53;
      .icon{
         font-size: 0.7333rem;
        margin-bottom:0.2667rem;
        color: #ffff00;
      }
    }
    .Img{
      margin-right: 0.4633rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.2733rem;
      height: 2.2733rem;
      border-radius: 0.2133rem;
      border: 0.0267rem solid #484C53;
      position: relative;
      .offImg{
        width: 0.8333rem;
        height: 0.8333rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    // justify-content: ;
  }
  .bottom-btn{
    width: 100%;
    height: 1.2267rem;
    // margin-bottom: 0.7467rem;
    background: #7578FF;
    border-radius: 0.6133rem;
    color: #FFFFFF;
    font-size: 0.4267rem;
    border: none;
  }
  .bottom-btn:disabled{
      opacity: 0.9; /* 设置禁用状态下的透明度 */
      background: #CFCFD7;
      color: #ffffff;
    }
   }

}

}

</style>
