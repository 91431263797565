import Request, { API } from '@/http/'
export function managementEvents (data) {
  Request.getData(API.managementEvents + `${data.pageName}/${data.action}`, {
    resistance: localStorage.getItem('adid') || '', // adid
    medical: localStorage.getItem('userRandomKey') || '', // userRandomKey
    monument: data.extInfo ? JSON.stringify(data.extInfo) : undefined// extInfo
  })
}

export const $managementEvents = managementEvents
export default {
  install (Vue, options) {
    console.log(options)
    Vue.prototype.$managementEvents = managementEvents
  }
}
