<template>
  <div class="content">
    <div class="headersbg">
      <div class="headers">
       <img src="@/assets/left.png" alt="" style="width:0.3467rem;height: 0.5867rem;" @click="go">
       <div class="box-payName">Repayment</div>
      <problemFeedback :title="title"></problemFeedback>

    </div>
    </div>

    <div class="content-box">

      <div class="box-top">
       <div class="box-title">
         <div class="title-left">
          <img :src="img" alt="" style="width: 1.0667rem;height: 1.0667rem;margin-right: 0.32rem;">
         <div style="color: #616162;font-size: 0.48rem">{{ productName }}</div>
        </div>
         <!-- <div class="title-right" v-if="showAutoApplybtn">
          <div style="font-size: 0.32rem;color: #8F939B;">Automatic loan</div>
          <img src="@/assets/question.png" alt="" style="height:0.3733rem;width:0.3733rem;margin:0 0.1067rem;" @click="showLoan">

          <van-switch v-model="checked" active-color="#0052d9 " inactive-color="#d5dae1"  size="0.48rem" @change="changeSwitch"/>

         </div> -->
       </div>
       <div class="box-line"></div>
       <div class="box-center">
        <div class="center-left">
           <!-- <img src="@/assets/amount.png" alt="" style="width:0.6933rem;height:0.5333rem;margin-right:0.25rem;"> -->
           <div class="left-name">Repayment amount</div>
        </div>
         <div class="center-right">
          <div style="font-size:0.81rem;color: #010101;font-weight: 600;" v-if="this.status==='170'">- ₹{{ repayAmount?dataMoney(repayAmount):'0' }}</div>
          <div style="font-size:0.81rem;color: #EE1F36;font-weight: 600;" v-if="this.status==='180'">- ₹{{ repayAmount?dataMoney(repayAmount):'0' }}</div>
          <div style="display: flex;justify-content: center;align-items: center;width: 0.5333rem;height: 0.5333rem;"  @click="showBox">
            <img :src="require('@/assets/bottom.png')" alt="" style="height:0.5333rem;width: 0.5333rem;margin-left: 0.1867rem;" v-if="showingImage">
            <img :src="require('@/assets/rightpay.png')" alt="" style="height:0.5333rem;width: 0.5333rem;margin-left: 0.1867rem;" v-if="!showingImage">
          </div>

         </div>
       </div>
       <div class="box-show" v-show="showingImage">
        <!-- <div class="box-line"></div> -->
         <div class="show-title">
          <div class="title-left">
            Repayment details
          </div>
          <!-- <img src="@/assets/rightOne.png" alt="" style="width: 0.16rem;height: 0.2667rem;margin-right:0.7467rem;" > -->
         </div>
         <div class="box-num" >
          <div class="numBox" v-for="(item,index) in list" :key="index">
            <div class="num-left">{{ item.suburb }}</div>
            <div class="num-right" v-html="item.acceptance"></div>
          </div>
        </div>
       </div>
       <div class="box-btn" @click="showLoanRepay">
        Repay now
       </div>
    </div>
    <div class="box-tip">
     <div class="tip-content">
        <div class="tips">1.After the UPI has expired, please re-enter the </div>
        <div class="tip-wrap">repayment page to start the repayment and get a </div>
        <div class="tip-wrap">new UPI.</div>
        <div class="tips">2.If the status of your paid order has not </div>
        <div class="tip-wrap">modified, please provide the following proof of</div>
        <div class="tip-wrap"> payment and we will handle the payment for you.</div>
     </div>
    </div>
    <div>
      <div class="box-bottom">
        <div class="title" style="margin-bottom: 0.2667rem;">Upload proof of payment</div>
    <div class="mian">
      <div class="userName">
        <van-form>
        <van-field
            v-model="userfrom.utr"
            class="field-phone"
            type="digit"
            placeholder="Enter UTR"
            autocomplete="off"
            @input="clickInput"
            />
          </van-form>
      </div>
       <van-form>
          <van-field
                v-model="userfrom.amount"
                class="field-vcode"
                autocomplete="off"
                left-icon=""
                type="digit"
                placeholder="Enter the exact amount of payment"
                @click="addCurrencySymbol"
                @input="inputCurrencySymbol"
                @blur="blurCurrencySymbol"
              >
           </van-field>
          </van-form>

    </div>
    <div class="title" style="margin-top: 0.7rem;margin-bottom: 0.2667rem;">Screenshot proof (optional)</div>
    <div class="bottomImg">
      <div class="Img" v-for="(item,index) in listImg" :key="index">
        <img :src="item" alt="" style=" width: 2.2733rem;height: 2.2733rem;">
        <img src="@/assets/off.png" alt="" class="offImg" @click="delImg(index)">
      </div>
      <div class="addImg" @click="addImg" v-if="listImg.length<3">
       <!-- <div style="width: 0.5333rem;height: 0.5333rem;"> -->
        <!-- <van-icon name="add-o"   class="icon"/> -->
       <!-- </div> -->
        <img src="@/assets/addImg.png" alt="" style="height: 0.84rem;width: 0.84rem;margin-bottom: 0.2rem;">
        <div style="font-size:0.2733rem;color: #AAB0BF;">Add pictures </div>
      </div>

      <!-- <div class="addImg">
        <img src="@/assets/addImg.png" alt="" style="height: 0.5333rem;width: 0.5333rem;margin-bottom:0.2667rem;">
        <img src="@/assets/addImg.png" alt="" >
      </div>
      <div class="addImg">
        <img src="@/assets/addImg.png" alt="" style="height: 0.5333rem;width: 0.5333rem;margin-bottom:0.2667rem;">
        <div style="font-size:0.3733rem;color: #AAB0BF;">Add pictures </div>
      </div> -->
    </div>
    <van-button type="primary" class="bottom-btn"  :disabled="!userfrom.utr||!userfrom.amount" @click="Submit">Submit</van-button>
      </div>
    </div>

    <agreeMent style="margin-bottom: 0.6667rem;"></agreeMent>
    </div>

    <loanDlog ref="loanDlog"></loanDlog>

  </div>
</template>

<script>
import { Toast } from 'vant'
import agreeMent from '@/components/agreeMent.vue'
import { base64ToImage } from '@/utils/baseImg'
import loanDlog from '@/components/loanDlog.vue'
import { dataMoney } from '@/utils/money'
import { getRepaymentDetail, getSettledAutoApply, getAutoApply } from '@/views/normalExtension/lib.js'
import { getUpOrderUtr } from '@/http/repayment'
import { getOrderDetails } from '@/views/order/lib'
import problemFeedback from '@/components/problemFeedback.vue'
// let isSymbolAdded = false;
export default {
  name: 'normalExtension',
  components: { agreeMent, loanDlog, problemFeedback },
  data () {
    return {
      checked: false,
      isListVisible: false,
      showingImage: false,
      loading: false,
      productName: false,
      repayAmount: false,
      isSymbolAdded: false,
      img: false,
      url: null,
      show: false,
      status: '',
      orderNo: localStorage.getItem('orderNo') || '',
      showAutoApplybtn: false,
      title: 'normalExtension',
      userfrom: {
        utr: '',
        amount: ''
      },
      listImg: [
      ],
      list: []

    }
  },
  watch: {
    'userfrom.amount': {
      handler (ne, old) {
        console.log(ne, 'ne----------')
        if (ne.length >= old.length && ne !== '₹') {
          const amount = this.userfrom.amount.replace(/₹/g, '')
          this.$managementEvents({ pageName: 'invention', action: 'infrastructure', extInfo: { amount: amount } })
        }
      }
    },
    listImg: {
      handler (newVal, oldVal) {
        // 在这里进行判断逻
        if (newVal === oldVal) {
          this.show = true
        }
        console.log(newVal, '------------')
        console.log(oldVal, '---------------')

        // 可以在这里做出你的判断逻辑
      },
      deep: true // 深度监听数组内部元素的变化
    }
  },
  methods: {

    // handleScroll () {
    //   window.scroll(0, 0)
    // },

    dataMoney,
    go () {
      this.$javaGet('returnH5')
    },
    showBox () {
      this.isListVisible = !this.isListVisible
      this.showingImage = !this.showingImage
    },
    // 是否展示自动复借按钮接口
    async getAutoApply () {
      const res = await getAutoApply()
      console.log(res, '到期还款页面')
      if (res.arm) {
        this.showAutoApplybtn = true
      } else {
        this.showAutoApplybtn = false
      }
    },
    // 根据订单详情判断是否开启复借按钮
    async getOrderDetails () {
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      // 订单详情
      const res = await getOrderDetails({
        pageType: 2,
        orderNo: this.orderNo
      })
      console.log(res)
      if (res.shake === '200') {
        loadingInstance.clear()
        this.loading = false
        this.list = res.arm.handful
        this.productName = res.arm.notion
        this.status = res.arm.repeat
        this.repayAmount = res.arm.busy
        this.img = base64ToImage(`${'data:image/png;base64,' + res.arm.newspaper}`)
        this.checked = res.arm.note
        this.$refs.loanDlog.settledAutoApplyAmount = res.arm.nod
        this.$refs.loanDlog.orderNo = this.orderNo
        this.$managementEvents({ pageName: 'invention', action: 'absolutely', extInfo: { type: this.checked, loanOrderId: this.orderNo } })
      }
    },
    async showLoanRepay () {
      const toast = Toast.loading({
        message: 'loading...',
        forbidClick: true, // 防止点击关闭
        duration: 0 // 设置为0表示不会自动关闭
      })
      if (this.loadingBtn) return
      this.loadingBtn = true
      //  this.changeSwitch()
      const res = await getRepaymentDetail({
        delayTerm: 0,
        orderNo: this.orderNo,
        requestType: 1
      })
      console.log(res, '订单跳转')
      this.url = res.arm.flight[0]?.crisis

      // 开启跳到h5还款页面 关闭这显示借款弹窗
      if (!this.showAutoApplybtn || this.status === '180') {
        //  window.location.href=this.url/
        console.log('进来了')

        this.$router.push({ name: 'easyPay', params: { url: this.url, name: 'normalExtension' } })

        toast.clear()
        //  this.url=res.data.repaymentCodeList[0].paymentCode
      } else {
        this.$refs.loanDlog.show = true
        this.$refs.loanDlog.showBtn = true
        this.$refs.loanDlog.url = this.url
        toast.clear()
        this.$refs.loanDlog.getBankCard()
      }

      setTimeout(() => {
        // 模拟操作完成后的处理
        console.log('操作完成')

        this.loadingBtn = false // 操作完成后将加载状态设置为false，允许下次点击
      }, 1000) // 这里的1000表示操作的耗时，实际情况根据具体操作来确定
    },
    // 切换调用是否自动复借接口，开启传true，关闭传false
    async changeSwitch (v) {
      console.log(v, '开启')
      this.$managementEvents({ pageName: 'invention', action: 'required', extInfo: { type: v, loanOrderId: this.orderNo } })
      await getSettledAutoApply({
        type: v,
        orderNo: this.orderNo
      })
    //  console.log(res,'开启传true');
    },

    clickInput () {
      this.$managementEvents({ pageName: 'invention', action: 'rent', extInfo: { utr: this.userfrom.utr } })
    },
    addCurrencySymbol () {
      // 当输入框被点击时，如果当前值为空，则添加金额符号
      if (this.userfrom.amount === '') {
        this.userfrom.amount = '₹'
      }
    },
    inputCurrencySymbol (value) {
      console.log(value, 'value')

      // 当输入框被点击时，如果当前值为空，则添加金额符号
      if (value === '') {
        this.userfrom.amount = '₹'
      }
      if (value && value.charAt(0) !== '₹') {
        this.userfrom.amount = '₹' + value
        console.log('触发了')
        //
      }
    },
    blurCurrencySymbol () {
      // 当输入框被点击时，如果当前值为空，则添加金额符号
      if (this.userfrom.amount === '₹') {
        this.userfrom.amount = ''
      }
    },
    delImg (index) {
      console.log(index)
      this.listImg.splice(index, 1)
    },
    addImg () {
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      this.$managementEvents({ pageName: 'invention', action: 'regional', extInfo: '' })
      // try {

      // } catch (error) {

      // }
      this.$javaGet('backImg', (res) => {
        console.log(res, '回来的图片base64')
        if (res === 'img') {
          return loadingInstance.clear()
        }
        if (this.listImg.length === 2 && res[0].length > 1) {
          Toast('Only select up to 3 images')
        } else if (this.listImg.length === 1 && res[0].length > 2) {
          Toast('Only select up to 3 images')
        } else if (this.listImg.length === 0 && res[0].length > 3) {
          Toast('Only select up to 3 images')
        } else {
          res[0].forEach(item => {
            this.listImg.push(`data:image/png;base64,${item}`)
          })
        }
        loadingInstance.clear()
        this.$managementEvents({ pageName: 'invention', action: 'progressive', extInfo: { img: this.listImg } })
      }).finally(() => {
        console.log('进来')
      })
      // this.$javaGet('backImg').then((res) => {
      //   console.log(res, 'res')
      //   if (res === '') {
      //     loadingInstance.clear()
      //     return
      //   }
      //   if (this.listImg.length === 2 && res[0].length > 1) {
      //     Toast('Only select up to 3 images')
      //   } else if (this.listImg.length === 1 && res[0].length > 2) {
      //     Toast('Only select up to 3 images')
      //   } else if (this.listImg.length === 0 && res[0].length > 3) {
      //     Toast('Only select up to 3 images')
      //   } else {
      //     res[0].forEach(item => {
      //       this.listImg.push(`data:image/png;base64,${item}`)
      //     })
      //   }
      //   loadingInstance.clear()
      //   this.$managementEvents({ pageName: 'invention', action: 'progressive', extInfo: { img: this.listImg } })
      // }).finally(() => {
      //   loadingInstance.clear()
      // })

      // }
    },
    dataURLtoFile (dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    async Submit () {
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      this.$managementEvents({ pageName: 'invention', action: 'go', extInfo: '' })
      const amount = this.userfrom.amount.replace(/₹/g, '')
      const res = await getUpOrderUtr({
        orderNo: this.orderNo,
        utr: this.userfrom.utr,
        img: this.listImg.map(item => this.dataURLtoFile(item)),
        amount: amount
      })
      if (res.shake === '200') {
        Toast('Submit Success')
        this.$managementEvents({ pageName: 'invention', action: 'province', extInfo: { utr: this.userfrom.utr } })
        setTimeout(() => {
          loadingInstance.clear()
          this.$javaGet('backHome')
        }, 500)
      } else {
        setTimeout(() => {
          loadingInstance.clear()
          // this.$javaGet('backHome')
        }, 1000)
      }
      console.log(res)
      console.log('点击了')
    },
    showLoan () {
      this.$refs.loanDlog.show = true
      this.$refs.loanDlog.showBtn = false
      this.$refs.loanDlog.getBankCard()
    }

  },

  // beforeDestroy () {
  //   window.removeEventListener('scroll', this.handleScroll, true)
  // },
  mounted () {
    // window.addEventListener('scroll', this.handleScroll, true)
    this.getAutoApply()
    this.getOrderDetails()
  }

}
</script>

<style lang="less" scoped>
::v-deep .van-field__control::-webkit-input-placeholder {
  color: #8F99A3;
  font-size:0.3467rem;
  font-weight: 600;
}

.box-line{
      width: 9.2533rem;
      height: 0.0267rem;
      opacity: 0.2;
      background: #8F99A3;
      margin-bottom: 0.56rem;
    }
.content{
  overflow:hidden;
  background: #FFFFFF;
  padding:0 0 1.12rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position:relative;
  // width: 0.64rem /* 24/37.5 *;
  .headersbg{
    width: 100%;
    height: 7.6533rem;
    background-image: url('@/assets/headersBg.png');
    background-size: 100% 100%;
    .headers{
    position: fixed;
    background: #7578ff;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99;
      width: 100%;
    height: 2.26rem;
    padding: 0.84rem 0.64rem 0 0.64rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
    .box-payName{
    margin: 1rem 0 1.1067rem 0.2667rem;
    font-weight: 600;
    font-size: 0.48rem;
    color: #FFFFFF;
    text-align: left;
  }

  }
  }

  // .headers{
  //   position: fixed;
  //     top: 0; /* 将导航栏固定在页面顶部 */
  //     left: 0;
  //     z-index: 99;
  //     background: #161931;
  //   width: 100%;
  //   height: 2.56rem;
  //   padding: 0.64rem 0.64rem 0 0.64rem;
  //   display:flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   .box-payName{
  //   font-weight: 600;
  //   font-size: 0.48rem;
  //   color: #FFFFFF;
  // }
  // }
  .content-box{
    width: 100%;
    overflow:auto;
  height: calc(100vh - 2.56rem);
  margin-top:-5.33rem;
  padding: 0 0.3733rem;

   .box-bottom{
    background: #FFFFFF;
    margin-top: 0.3733rem;
    padding: 0.48rem 0.56rem 0.48rem 0.56rem;
    border-radius: 0.2667rem;
    margin-bottom: 1.0667rem;
    .title{
    width: 100%;
    text-align: initial;
    color: #626C89;
    // font-weight: 600;
    font-size: 0.3733rem;
  }
  ::v-deep  .van-field__control{
  font-size: 0.4267rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: center;
  color: #010101 !important;
}

  .mian{
      .userName{
        display: flex;
        margin-bottom: 0.2667rem;
             .field-phone{
              width: 8.16rem;
              height: 1.2267rem;
              margin-bottom: 0.1733rem;
              border-radius: 0.5867rem;
              // border: 0.0267rem solid #0B718D;
              display: flex;
              align-items: center;
              background: #F5F5FC;
             }
      }
      .field-vcode{
        width: 8.16rem;
        height: 1.2267rem;
          border-radius: 0.5867rem;
          display: flex;
          align-items: center;
          // border: 0.0267rem solid #0B718D;
          background: #F5F5FC;
      }

  }
  .bottomImg{
    width: 100%;
    display: flex;
    flex: 1;
    margin-bottom:0.8rem;
    // justify-content:space-between;
    .addImg{
      // margin-right: 0.5333rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 2.2733rem;
      height: 2.2733rem;
      background: #F5F5FC;
      border-radius: 0.2533rem;
      // border: 0.0267rem solid #484C53;
      .icon{
         font-size: 0.7333rem;
        margin-bottom:0.2667rem;
        color: #ffff00;
      }
    }
    .Img{
      margin-right: 0.4633rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.2733rem;
      height: 2.2733rem;
      border-radius: 0.2133rem;
      // border: 0.0267rem solid #484C53;
      position: relative;
      .offImg{
        width: 0.8333rem;
        height: 0.8333rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    // justify-content: ;
  }
  .bottom-btn{
    width: 100%;
    height: 1.2267rem;
    // margin-bottom: 0.7467rem;
    background: #7578FF;
    border-radius: 0.6133rem;
    color: #FFFFFF;
    font-size: 0.4267rem;
    border: none;
  }
  .bottom-btn:disabled{
      opacity: 0.9; /* 设置禁用状态下的透明度 */
      background: #CFCFD7;
      color: #ffffff;
    }
   }
  .box-top{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0.64rem 0.48rem;
    border-radius: 0.64rem;
    background: #EDEEFA;
    .box-title{
      width: 100%;
      margin-bottom: 0.2133rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-left{
        display: flex;
        align-items: center;

      }
      .title-right{
        display: flex;
        align-items: center;
      }
    }

    .box-center{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .center-left{
        // margin-left: 0.6933rem;
        // margin-bottom:0.64rem;
         display: flex;
         align-items: center;
        .left-name{
          font-weight: 600;
          font-size: 0.3733rem;
          color: #616162;
        }
      }
      .center-right{
        // margin-right:0.4rem;
        display: flex;
        align-items: center;
      }
    }
    .box-show{
      width: 100%;
      margin-top: 0.48rem;
       display: flex;
       flex-direction: column;
       .show-line{
        width: 8.4rem;
        height: 0.0267rem;
        background: #FFFFFF;
        opacity: 0.16;
        margin-bottom: 0.56rem;
       }
       .show-title{
        display: flex;
        padding:0 0.3733rem;
        justify-content: space-between;
        .title-left{
           font-size: 0.4267rem;
           color: #626C89;
           font-weight: 600;
        }
       }
       .box-num{
        display: flex;
        margin-top: 0.3467rem;

        flex-direction: column;
        .numBox{
          display: flex;
          padding:0 0.3733rem;
          justify-content: space-between;

          margin-bottom: 0.4267rem;
          .num-left{
            font-size: 0.32rem;
            font-weight: 400;
            color: #616162;
          }
          .num-right{
            font-size: 0.32rem;
            font-weight: 600;
            color: #616162;
          }
        }
      }
    }
    .box-btn{
      margin-top:0.5267rem;
      width: 5.3333rem;
      height: 1.2267rem;
      border-radius: 1.6rem;
      display: flex;
      background: #7578FF;
      justify-content: center;
      align-items: center;
      font-size: 0.4267rem;
      font-weight: 600;
      color: #FFFFFF;
    }

  }
  .box-tip{
    padding: 0.2667rem 0.4267rem;
    margin-top: 0.3733rem;
    width: 100%;
    height: 3.84rem;
    display: flex;
    align-items: center;
  //  justify-content: center;
  background: #FBE7E7;
  // box-shadow: 0 0.1067rem 0.2667rem 0 rgba(14,16,36,0.81);
    border-radius:0.64rem;
    .tip-content{
      display: flex;
      flex-direction: column;
      text-align: initial;
      .tips{
        font-size: 0.3567rem;
        color: #D80B33;
        margin-bottom: 0.1333rem;
      }
      .tip-wrap{
        margin-bottom: 0.1333rem;
        font-size: 0.3567rem;
        color: #D80B33;
      }
    }
  }

  }

}

</style>
