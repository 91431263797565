import 'babel-polyfill'
import Es6Promise from 'es6-promise'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/flexible'
import loading from '@/utils/loading'
import VConsole from 'vconsole'
import javaBack from '@/utils/javaBack'
import Vant from 'vant'
import javaGet from '@/utils/javaGet'
import managementEvents from '@/utils/managementEvents'
import 'vant/lib/index.css'
Es6Promise.polyfill()
Vue.prototype.$javaBack = javaBack
Vue.prototype.$javaGet = javaGet
Vue.use(Vant)

Vue.use(loading)
Vue.use(managementEvents)
Vue.config.productionTip = false
const isDev = process.env.NODE_ENV === 'development'
isDev && new VConsole()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
