const dsBridge = require('dsbridge')

export default {
  install (Vue, options) {
    Vue.prototype.$javaBack = function (don) {
      console.log(options)
      dsBridge.call('ios.handle', JSON.stringify([don])
      )
    }
  }
}
