<template>
  <div>
    <van-overlay :show="show" @click="show = false">
  <div class="wrapper" @click.stop>
    <div class="block" >
      <div class="content">
        <div class="content-title">
          Feedback
        </div>
        <div class="content-name">
          Please provide a description of the issue <br>you met:
        </div>
      </div>
      <van-form  class="from">
       <van-field
            v-model="form.content"
            rows="1"
            autosize
            type="textarea"
             maxlength="200"
             show-word-limit
            class="field"
            placeholder="Please enter"
        >
        <!-- <template  #left-icon>
        <img src="@/assets/feedback.png" alt="" class="placeholder-icon" >
       </template> -->
      </van-field>
      <van-button type="primary" size="large" class="btn-top" @click="onSubmit" :disabled="disabled" >Submit</van-button>
    </van-form>
    <div class="titl-bottom">
      For direct assistance, you can call our customer<br> service:
    </div>
    <div class="bottom-btn">
      <div class="btn-left"  :data-clipboard-text="form.vcode">{{ form.vcode }}</div>
      <div class="btn-right" @click="copy">Copy</div>
    </div>
    <img  src="@/assets/offbtn.png" alt=""  @click="close" class="close-icon"/>
    </div>

  </div>

</van-overlay>
  </div>
</template>

<script>
import ClipboardJS from 'clipboard'
import { getCustomerPhone, getComplaint } from '@/views/order/lib'
import { Toast } from 'vant'

export default {
  data () {
    return {
      show: false,
      title: '',
      form: {
        content: '',
        vcode: ''

      }

    }
  },
  computed: {
    disabled () {
      return !this.form.content
    }

  },
  methods: {
    close () {
      this.form = {
        content: '',
        vcode: ''

      }
      this.show = false
    },
    async onSubmit () {
      const res = await getComplaint({
        content: this.form.content,
        title: this.title
      })
      if (res.shake === '200') {
        Toast('Sent successfully')
        this.form = {
          content: '',
          vcode: ''

        }
        this.show = false
      }
    },
    input () {

    },
    async getCustomerPhone () {
      const res = await getCustomerPhone()
      console.log(res, 'res')
      this.form.vcode = res.arm.govern
    },
    copy () {
      if (this.copynum) {
        this.copynum.destroy()
      }
      this.copynum = new ClipboardJS('.btn-left')
      this.copynum.on('success', (e) => {
        Toast('Copy Success')
        e.clearSelection()
      })
      this.copynum.on('error', function (e) {
        console.error('Action:', e.action)
        console.error('Trigger:', e.trigger)
      })
      this.copynum.onClick({ currentTarget: document.querySelector('.btn-left') })
    }

    // 获取客服电话回显
    // async getCustomerServiceInfo () {
    //   const res = await getCustomerServiceInfo()
    //   this.form.vcode = res.data.phone
    // },
    // async onSubmit () {
    //   const res = await getcomplaint({
    //     content: this.form.content,
    //     title: this.title
    //   })
    //   if (res.code === '200') {
    //     Toast('Sent successfully')
    //     this.form = {
    //       content: '',
    //       vcode: ''

    //     }
    //     this.show = false
    //   }
    // },
    // input (value) {
    //   localStorage.setItem('form', value)
    // },

  },
  created () {
  },
  mounted () {
  }

}
</script>

<style lang="less" scoped>

.van-cell{
  background: #F5F5FC;
}
::v-deep .van-cell::after{
  position:static;
}
::v-deep .van-field__control::-webkit-input-placeholder{
  color: #8F99A3; /* 设置占位符文本的颜色 */
  font-weight: 600;
  font-size: 0.32rem;
}
::v-deep .van-field__control {
  color: #626C89;
  font-weight:700;
  height:3.48rem!important;
}
.from{
  width: 7.52rem;
 display: flex;
 flex-direction: column;
align-items: center;
.placeholder-icon{
  margin-top:0.0533rem;
  width:0.2667rem;
  height: 0.2933rem;

}
}
.field{
  height: 4.48rem;
  border-radius: 0.2667rem ;
  // border: 0.0533rem solid #8F99A3;
  margin: 0.2667rem 0;

}
 .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    // justify-content: center;
    // margin-top: 3.9467rem;
    padding:0 0  0.8533rem 0;
    width: 8.8rem;
    height: auto;
    // background: #222222;
    border-radius:0.64rem;
    .titl-bottom{
      width: 100%;
      text-align: initial;
      font-weight: 600;
      margin-top: 0.4267rem;
      font-size:0.32rem;
      color: #FFFFFF;
    }
    .bottom-btn{
      width: 7.52rem;
      // height: 1.36rem;
      margin-top:0.4267rem;
      display: flex;
      justify-content: space-between;
      .btn-left{
        display: flex;
        // align-items: center;
        // justify-content: center;
        font-size: 0.3467rem;
        color: #010101;
        width: 4.78rem;
        height: 1.28rem;

        background: #F5F5FC;
        border-radius: 0.5267rem;
        padding: 0.4267rem 0 0.4267rem 0.4267rem;
      }
      .btn-right{
      width: 2.4267rem;
      height: 1.28rem;
      background: #69BDC7;
      border-radius: 0.64rem;
      display: flex;
      font-size:0.4267rem;
      font-weight: bold;
      color: #FFFFFF;
      justify-content: center;
      align-items: center;
      }
     }

    .content{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 1.0667rem;
      border-radius:0.64rem;
      background: linear-gradient( 180deg, #DEDEFF 0%, rgba(255,255,255,0) 100%);
     .content-title{
      font-size: 0.5333rem;
      color: #010101;
      font-weight: 700;
      font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
      margin-bottom:0.5867rem;
     }
     .content-name{
      width: 7.52rem;
      font-weight: 600;
      font-size: 0.32rem;
      color: #626C89;
      text-align: left;
      font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
      margin-bottom:0.24rem;
     }

    }
    .btn-top{
      background: #7578FF;
      height: 1.28rem;
      width: 7.52rem;
      border-radius: 0.8rem;
      border: none;
      margin-top: 0.1333rem;
      color: #FFFFFF;
      font-weight: 800;
      font-size: 0.4267rem;
    }
    .btn-top:disabled{
      opacity: 0.9; /* 设置禁用状态下的透明度 */
      background: #CFCFD7;
      color: #ffffff;
    }
  }
  .close-icon {
    width: 0.64rem;
    height: 0.64rem;
    // margin-top: 1.4133rem;
    border: none;
    top: 0.5rem;
    right:0.5rem;
    position: absolute;
  }

</style>
