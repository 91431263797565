<template>
  <div>
    <img src="@/assets/customer.png" alt="" class="imgcustomer" @click="showCustomer">
    <problemFeedDlog ref="problemFeedDlog"></problemFeedDlog>
  </div>
</template>

<script>
import problemFeedDlog from '@/components/problemFeedDlog'
export default {
  components: { problemFeedDlog },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    showCustomer () {
      this.$refs.problemFeedDlog.show = true
      this.$refs.problemFeedDlog.getCustomerPhone()
      this.$refs.problemFeedDlog.title = this.title
    }

  },
  mounted () {

  }

}
</script>

<style lang="less" scoped>
 .imgcustomer{
       margin-top: 0.233rem;
        width: 1.1rem;
        height: 1.1rem;
      }
</style>
