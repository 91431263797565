// function bind (el) {
//   const mask = document.createElement('div')
//   mask.style.width = '100%'
//   mask.style.height = '100%'
//   mask.className = 'loading-mask'

//   const loading = document.createElement('img')
//   loading.setAttribute('src', require('@/assets/logo.png'))
//   loading.style.position = 'absolute'
//   loading.style.margin = 'auto'
//   loading.style.top = 0
//   loading.style.bottom = 0
//   loading.style.left = 0
//   loading.style.right = 0
//   loading.style.width = '1rem'
//   mask.appendChild(loading)
//   el.style.position = 'relative'
//   el.appendChild(mask)
// }
// export default {
//   install (Vue, options) {
//     Vue.directive('loading', {
//       bind (el, binding, vnode, oldVnode) {
//         if (binding.value && binding.oldValue !== binding.value) {
//           bind(el)
//         }
//       },
//       update (el, binding, vnode, oldVnode) {
//         if (!binding.value && binding.oldValue !== binding.value) {
//           const nowMask = el.classList[0] ? document.querySelector(`.${el.classList[0]} .loading-mask`) : document.querySelector('.loading-mask')
//           el.removeChild(nowMask)
//           el.style.position = ''
//         } else if (binding.value && binding.oldValue !== binding.value) {
//           bind(el)
//         }
//       }
//     })
//   }
// }
// 修改自定义指令，使用 Vant 的 Loading 工具函数来展示加载动画
export default {
  install (Vue, options) {
    Vue.directive('loading', {
      bind (el, binding) {
        if (binding.value) {
          const loading = document.createElement('div')
          loading.className = 'custom-loading'
          el.appendChild(loading)

          const instance = new Vue({
            render: (h) => h('van-loading', {
              props: {
                type: 'spinner',
                color: '#1989fa',
                value: true
              },
              style: {

                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999
              },
              on: {
                input: (val) => {
                  if (!val) {
                    instance.$destroy()
                    el.removeChild(loading)
                  }
                }
              }
            })
          }).$mount()

          loading.appendChild(instance.$el)
        }
      },
      update (el, binding) {
        if (binding.value !== binding.oldValue) {
          if (binding.value) {
            // 显示加载动画
          } else {
            // 隐藏加载动画
            const loading = el.querySelector('.custom-loading')
            if (loading) {
              el.removeChild(loading)
            }
          }
        }
      }
    })
  }
}
