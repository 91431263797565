import Request, { API } from '@/http/index.js'
// 获取订单详情
export async function getOrderDetails (data) {
  const res = await Request.getData(API.order.dataDetails + `?transition=${data.orderNo}&letter=${data.pageType}`)
  return res
}

// 展期订单详情
export async function getDelayFlexble (data) {
  const res = await Request.getData(API.order.DelayFlexble, {
    weave: data.delayTerm,
    transition: data.orderNo,
    outlet: data.termUnit
  })
  return res
}

// 还款详情
export async function getRepaymentDetail (data) {
  const res = await Request.getData(API.order.RepaymentDetail, {
    weave: data.delayTerm,
    transition: data.orderNo,
    season: data.requestType
  })
  return res
}
// 更新订单utr接口
export async function getUpOrderUtr (data) {
  const res = await Request.getData(API.order.UpOrderUtr + `?orderNo=${data.orderNo}&utr=${data.utr}&amount=${data.amount}&img=${data.img}`)
  return res
}
