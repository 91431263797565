<template>
  <div>
    <van-nav-bar
      :title="title"
      @click-left="goBack">
      <span class="left" slot="left">
        <img src="@/assets/left.png" alt="">
      </span>
      </van-nav-bar>
  </div>
</template>

<script>
export default {
  name: 'heaDer',
  props: {
    title: {
      type: String
    }
  },
  data () {
    return {
      show: null
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }

}
</script>

<style scoped lang="less">
.van-nav-bar {
  height: 2.56rem;
  padding-top:0.8rem;
  background-color: #7578ff;
 ::v-deep .van-nav-bar__title {
    color: #FFFFFF;
    font-size: 0.48rem;
    padding-top: 0.2667rem;
  }
  .left {
    display: flex;
    align-items: center;
    img {
      width: 0.3467rem;
      height:0.5867rem;
      padding-top: 0.2667rem;
    }
  }
}
</style>
