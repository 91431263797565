<template>
  <div class="content">
    <div class="headers">
       <img src="@/assets/left.png" alt="" style="width:0.3467rem;height: 0.5867rem;" @click="go">
       <div style="font-size:0.48rem;color: #FFFFFF;font-weight:600;text-align:left;" v-if="status==='90'">Order issuing</div>
      <div style="font-size:0.48rem;color: #FFFFFF;font-weight:600;text-align:left;" v-if="status==='150'">Order auditing</div>
      <div style="font-size:0.48rem;color: #FFFFFF;font-weight:600;text-align:left;" v-if="status==='100'">Order approved</div>
      <div style="font-size:0.48rem;color: #FFFFFF;font-weight:600;text-align:left;" v-if="status==='170'">Order issued</div>
       <!-- <problemFeedback :title="title"></problemFeedback> -->
      <div style="width:0.64rem;height: 0.64rem;"></div>
    </div>
    <div class="mian">

      <div class="Details">
      <div class="Details-name">
        <van-skeleton title avatar  :loading="loading">
        <img :src="img" alt="" style="width: 1.0667rem;height: 1.0667rem;margin-right: 0.32rem;">
      </van-skeleton>
        <div style="color: #FFFFFF;font-size:0.48rem;font-weight: 600;">{{ productName }}</div>
      </div>
      <div class="box-line"></div>
      <div class="Details-box">
        <div class="box-name">
          <div class="name-left">Loan Details</div>
        </div>
        <div class="box-num">
          <div class="numBox" v-for="(item,index) in list" :key="index">
            <div class="num-left">{{ item.key }}</div>
            <div class="num-right" v-html="item.value"></div>
          </div>

        </div>

      </div>

    </div>
    <div class="loan" @click="loan('Loan Agreement')"  v-if="status==='170'"> See loan contract></div>
   <div style="width: 100%;display: flex;justify-content: center;">
    <div class="bottom-btn" v-if="status==='170'" @click="gonormalExtension">
      Repay
    </div>
   </div>
    <agreeMent class="agreeMent"></agreeMent>
    </div>

  </div>
</template>

<script>
import { Toast } from 'vant'
import { base64ToImage } from '@/utils/baseImg'
import agreeMent from '@/components/agreeMent.vue'
// import problemFeedback from '@/components/problemFeedback.vue'
import { getOrderDetails } from '@/views/order/lib'

export default {
  name: 'orDer',
  components: { agreeMent },
  data () {
    return {
      title: 'orDer',
      orderNo: localStorage.getItem('orderNo') || '',
      productName: null,
      loading: false,
      img: null,
      status: null,
      type: null,
      list: []
    }
  },
  methods: {
    go () {
      this.$javaGet('returnH5')
    },
    async getOrderDetails () {
      const loadingInstance = Toast.loading(
        {
          message: 'Loading...',
          duration: 0, // 设置为 0 表示持续加载直到手动关闭
          forbidClick: true, // 防止用户点击
          closeOnClick: false // 设置为 true 可以点击关闭 loading
        }
      )
      const res = await getOrderDetails({
        pageType: 1,

        orderNo: this.orderNo
      })
      console.log(res, '订单详情')
      if (res.code === '200') {
        this.status = res.data.status
        console.log(this.status, 'this.status')
        loadingInstance.clear()
        this.loading = false
        this.list = res.data.pairs
        this.productName = res.data.productName
        this.img = base64ToImage(`${'data:image/png;base64,' + res.data.slogan}`)
        if (this.status === '90') {
          this.type = 'AUDITING'
        } else if (this.status === '100') {
          this.type = 'APPROVED'
        } else if (this.status === '150') {
          this.type = 'ISSUING'
        } else if (this.status === '170') {
          this.type = 'ISSUED'
        }
        this.$managementEvents({ pageName: 'Orders', action: 'Enter', extInfo: { type: this.type, loanOrderId: this.orderNo } })
      }
    },
    gonormalExtension () {
      this.$router.replace('normalExtension')
    },
    // 借款协议
    loan (name) {
      this.$router.push({ name: 'AgreeMent', query: { name } })
    }

  },
  mounted () {
    // this.$managementEvents({ pageName: 'function', action: 'private', extInfo: {  type: this.type, loanOrderId: this.orderNo } })
    this.getOrderDetails()
  },
  created () {

  }
}
</script>

<style lang="less" scoped>

.content{
  // width: 100%;
  height:100%;
  background: #161931;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .headers{
    width: 100%;
    height: 2.72rem;
    padding: 0.64rem 0.64rem 0 0.64rem;
    display:flex;
    background: #161931;
    justify-content: space-between;
    align-items: center;
    position: fixed;
      top: 0; /* 将导航栏固定在页面顶部 */
      left: 0;
      z-index: 99;
  }
.mian{
  padding:0 0.64rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  // display: grid;
  // place-items: center;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  padding-bottom:0.8rem;
  margin-top: 2.72rem;
  position: relative;
  .agreeMent{
    width: 100%;
    position: absolute;
    // bottom: 0;
    left: 50%;
  transform: translate(-50%, -50%);
  }
  .Details{
    width:100%;
    margin-top: 0.2667rem;
    padding: 0.4267rem 0.4267rem 0 0.4267rem;
    background: #262845;
    display: flex;
    margin-bottom: 0.56rem;
    // margin-bottom: 29.3333rem;
    flex-direction: column;
    box-shadow: 0 0.0267rem 0.1067rem 0 rgba(0,0,0,0.25), inset 0 0.0267rem 0.4267rem 0 rgba(255,255,255,0.1), 0 0.1067rem 0.1067rem 0 rgba(0,0,0,0.25);
    border-radius: 0.32rem;
    .Details-name{
      margin-bottom: 0.2133rem;
      display: flex;
      align-items: center;
    }
    .box-line{
        width: 100%;
        height: 0.0267rem;
        background: #FFFFFF;
        opacity: 0.16;
        margin-bottom: 0.5067rem;
      }
    .Details-box{
      display: flex;
      flex-direction: column;
      .box-name{
        display: flex;
       justify-content: space-between;
     .name-left{
        font-size: 0.4267rem;
        margin-bottom: 0.4533rem /* 17/37.5 */;
        color: #ffffff;
       }
     .name-right{
        font-size: 0.3733rem;
        margin-bottom: 0.32rem;
        color: #71DFA5 ;
       }
      }

      .box-num{
        display: flex;
        flex-direction: column;
        .numBox{
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.4533rem /* 17/37.5 */;
          .num-left{
            font-size: 0.32rem;
            color: #75778B;
          }
          .num-right{
            width: 3.5rem;
            text-align: right;
            word-wrap: break-word; /* 使用 word-wrap */
            font-weight: 600;
            font-size: 0.32rem;
            color: #00CCFF;
          }
        }

      }
    }

  }
  .loan{
          text-align: right;
          // font-weight: 600;
          font-size:0.32rem;
          margin-bottom: 2.6667rem;
          color: #FFFF00;
        }
  .bottom-btn{

    margin-bottom: 3.3067rem;
    width:7.4667rem ;
    height:1.2267rem;
    // text-align:center;
    border-radius: 0.6133rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color: #171726;;
    font-size:0.4267rem;
    box-shadow: 0 0 0.16rem  rgba(14,13,16,0.4);
    background: #FFFF00;
  }
}

}

</style>
