import { render, staticRenderFns } from "./problemFeedDlog.vue?vue&type=template&id=48cb7aa3&scoped=true"
import script from "./problemFeedDlog.vue?vue&type=script&lang=js"
export * from "./problemFeedDlog.vue?vue&type=script&lang=js"
import style0 from "./problemFeedDlog.vue?vue&type=style&index=0&id=48cb7aa3&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48cb7aa3",
  null
  
)

export default component.exports