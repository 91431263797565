export default {
  managementEvents: '/clothing/incident/zone/', // 打点,
  order: {
    dataDetails: '/clothing/previously/alike', // 订单详情
    AutoApply: '/clothing/previously/organic', // 是否展示自动复借按钮
    SettledAutoApply: '/clothing/previously/gross', // 是否自动复借
    RepaymentDetail: '/clothing/previously/surely', // 还款详情
    UpOrderUtr: '/api/order/up-order-utr', // 更新订单utr
    DelayFlexble: '/clothing/board/ridge', // 展期订单详情
    BankCard: '/clothing/tragic/inflation' // 获取银行卡列表
  },
  customer: {
    CustomerPhone: '/clothing/incident/engage', // 获取客服电话
    Complaint: '/clothing/prominent/shape'// 获取客服电话
  }
}
