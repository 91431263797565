import Request, { API } from '@/http/index.js'

// 获取订单详情
export async function getOrderDetails (data) {
  const res = await Request.getData(API.order.dataDetails + `?transition=${data.orderNo}&letter=${data.pageType}`)
  return res
}
// 获取客服电话
export async function getCustomerPhone () {
  const res = await Request.getData(API.customer.CustomerPhone)
  return res
}
// 用户意见反馈按钮
export async function getComplaint (data) {
  const res = await Request.getData(API.customer.Complaint, {
    influential: data.content,
    praise: data.title
  })
  return res
}
